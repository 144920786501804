<template>
    <div>
        <p>User dashboard {{profile.email}}</p>
        <el-button type="primary" @click="logout">Logout</el-button>
    </div>

</template>

<script>
export default {
    name: "Dashboard",
    computed: {
        profile: function () {
            return this.$store.getters.PROFILE
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('SESSION_DELETE_REQUEST')
                .finally(() => this.$router.push('/login'))
        }
    },
    mounted() {
        this.$store.dispatch('PROFILE_GET_REQUEST')
    }
}
</script>

<style scoped>

</style>
